@use "~booksprout-app/src/css/quasar.variables.sass" as *
@use "~booksprout-app/src/css/mixins/media.sass" as *
@use '~booksprout-app/src/css/functions/rem-fn' as *
@use '~booksprout-app/src/css/functions/line-height-fn' as *
@use 'src/css/functions/get-color' as *
@use '~booksprout-app/src/css/mixins/media' as media-query
@use 'src/css/variables/media' as media
@use 'src/css/variables/border-radius' as *
@use 'src/css/variables/box-shadow' as *
@use 'src/css/variables/z-index' as *

.bs-w-author
  article
    &:first-child
      .bs-w-article-content
        padding-block-start: rem-fn(30)

        @include media-query.from(media.$md-min)
          padding-block-start: 0

    &:nth-child(even)
      background-color: $bs-light-grey-background
      position: relative

    h2
      margin-block-end: rem-fn(40)
      max-inline-size: rem-fn(259)

      @include media-query.from(media.$md-min)
        margin-block-end: rem-fn(30)
        max-inline-size: 100%

.bs-w-a-intro
  .bs-w-article-content
    @include media-query.from(media.$md-min)
      display: grid
      grid-template-areas: 'header ellipse' 'subtitle ellipse' 'button ellipse'
      grid-template-columns: rem-fn(760) 1fr
      grid-template-rows: repeat(2, max-content) 1fr
      position: relative

      h1
        grid-area: header
        margin-block-start: rem-fn(85)

      > p
        grid-area: subtitle
        margin-block-end: rem-fn(50)
        max-inline-size: rem-fn(538)

      .bs-w-button
        grid-area: button

      .bs-w-member-ellipse
        grid-area: ellipse

.bs-w-a-bad-reviewer
  .bs-w-chip
    bottom: rem-fn(3)
    background-color: get-color(bs-rm)
    color: get-color(bs-w)
    position: absolute
    text-transform: uppercase

.bs-w-a-b-r-details
  display: flex
  flex-flow: column
  row-gap: rem-fn(30)

  .bs-w-a-b-r-d-name
    display: flex
    flex-flow: column
    text-align: center

    strong
      color: get-color(bs-rm)
      font-size: rem-fn(16)
      line-height: line-height(22, 16)
      margin-block-end: rem-fn(8)

    span
      color: get-color(bs-gl)

  /* custom media */
  @include media-query.from(1300)
    align-items: center
    display: grid
    grid-template-columns: rem-fn(190) 1fr rem-fn(120)
    gap: rem-fn(40) rem-fn(30)

    .bs-w-a-b-r-d-name
      text-align: left

.bs-w-a-b-r-d-rating
  align-items: center
  gap: rem-fn(20)
  display: flex
  flex-flow: column

  .bs-w-rating
    flex-flow: column

    span:not(.q-icon)
      font-size: rem-fn(24)
      font-weight: 400
      line-height: line-height(33, 24)
      margin-block-end: rem-fn(20)
      order: -1

  > span
    color: get-color(bs-gm)

.bs-w-a-b-r-d-reason
  border-radius: $border-radius-base
  box-shadow: $box-shadow-base
  display: flex
  flex-flow: column
  grid-column: span 3
  padding: rem-fn(30)
  position: relative

  strong
    color: get-color(bs-rm)
    margin-block-end: rem-fn(2)

  p
    color: get-color(bs-gl)
    margin-block-end: 0

  &:before
    content: ''
    border-left: rem-fn(12) solid transparent
    border-right: rem-fn(12) solid transparent
    border-bottom: rem-fn(12) solid get-color(bs-w)
    height: 0
    left: calc(50% - #{rem-fn(8)})
    width: 0
    position: absolute
    top: rem-fn(-10)
    z-index: $popup-z-index - 1

    /* custom media */
    @include media-query.from(1300)
      left: rem-fn(85)

.bs-w-a-sub
  margin-block-end: rem-fn(60)

.bs-w-a-pros
  h2
    margin-block-end: rem-fn(69)

    @include media-query.from(media.$md-min)
      margin-block-end: 0
      margin-inline: auto
      max-inline-size: rem-fn(657)

  .bs-w-card
    &:not(:last-child)
      margin-block-end: rem-fn(60)

      @include media-query.from(media.$md-min)
        margin-block-end: 0

    svg
      color: get-color(bs-nb)

  @include media-query.from(media.$md-min)
    .bs-w-article-content
      display: grid
      grid-template-columns: repeat(3, 1fr)
      gap: rem-fn(100) rem-fn(40)
      text-align: center

      h2
        grid-column: span 4

      .bs-w-button
        grid-column: span 4
        justify-self: center


.bs-w-a-new-author
  p
    margin-block-end: rem-fn(60)

  .bs-w-member-carousel
    margin-block-end: rem-fn(60)
    max-inline-size: 100%

  /* custom media */
  @include media-query.from(1300)
    .bs-w-article-content
      display: grid
      grid-template-areas: 'heading heading' 'text reviewers' 'button reviewers'
      grid-template-columns: rem-fn(600) 1fr
      gap: rem-fn(30) rem-fn(200)

      h2, p, img
        margin-block-end: 0

      h2
        grid-area: heading

      p
        grid-area: text

      .bs-w-member-carousel
        grid-area: reviewers
        margin-block-end: 0

      .bs-w-button
        grid-area: button
        margin-block-start: rem-fn(10)


.bs-w-a-easiness
  p, strong
    margin-block-end: rem-fn(40)

  strong
    display: inline-block

  svg
    color: get-color(bs-g)

  .bs-w-card
    &:not(:last-child)
      margin-block-end: rem-fn(40)

  @include media-query.from(media.$md-min)
    .bs-w-article-content
      column-gap: rem-fn(140)
      display: grid
      grid-template-columns: repeat(3, 1fr)
      text-align: center

      h2, p, strong
        grid-column: span 3

      p
        margin-inline: auto
        max-inline-size: rem-fn(608)

.bs-w-a-reviews
  p
    margin-block-end: rem-fn(57)

  @include media-query.from(media.$md-min)
    .bs-w-article-content
      display: grid
      gap: rem-fn(30) rem-fn(20)
      grid-template-areas: 'header reviewer' 'text reviewer' 'button reviewer'
      grid-template-columns: rem-fn(600) 1fr

      h2, p, .bs-w-reviewer-preview
        margin-block-end: 0

      h2
        grid-area: header

      p
        grid-area: text

      .bs-w-button
        grid-area: button

      .bs-w-reviewer-preview
        grid-area: reviewer


.bs-w-reviewer-preview
  margin-block-end: rem-fn(60)

.bs-w-a-testimonial
  h2
    max-inline-size: 100%

  p
    color: get-color(bs-b)
    font-size: rem-fn(20)
    line-height: line-height(27, 20)
    margin-block-end: rem-fn(50)

  .bs-w-member
    margin-block-end: rem-fn(93)
    margin-inline: rem-fn(25)

  @include media-query.from(media.$md-min)
    text-align: center

    p
      font-size: rem-fn(26)
      line-height: line-height(36, 26)
      margin-block-end: rem-fn(60)
      margin-inline: auto
      max-inline-size: rem-fn(900)

    .bs-w-member
      justify-content: center
      margin-block-end: rem-fn(103)

      figcaption
        text-align: left

    .bs-w-button
      margin-inline: auto


.bs-w-a-management
  .bs-w-card
    svg
      color: get-color(bs-nb)

    &:not(:first-of-type)
      svg
        color: get-color(bs-g)

    figcaption
      display: flex
      flex-flow: column

      > span
        color: get-color(bs-gm)
        margin-block-end: rem-fn(40)

    .bs-w-popup
      top: rem-fn(144)

    &:not(:last-of-type)
      margin-block-end: rem-fn(354)

  @include media-query.from(media.$md-min)
    .bs-w-article-content
      display: grid
      grid-template-columns: rem-fn(440) 1fr
      gap: rem-fn(30) rem-fn(200)

      h2
        grid-column: span 2
        margin: 0

      .bs-w-card
        padding-inline: 0
        text-align: left

        figcaption
          display: flex
          flex-direction: column

          > span
            margin-block-end: rem-fn(30)

        &:not(:last-of-type)
          margin-block-end: rem-fn(195)

.bs-w-a-services
  h2
    max-inline-size: 100%

  .bs-w-a-sub
    margin-block-end: rem-fn(60)

  @include media-query.from(media.$md-min)
    text-align: center

    .bs-w-a-sub, .bs-w-button
      margin-inline: auto

    .bs-w-a-sub
      margin-block-end: rem-fn(40)
      max-inline-size: rem-fn(600)

// to override bg color needs to have higher specificity
.bs-w-author article.bs-w-a-free-account
  background-color: get-color(bs-nb)

  &:before
    background-color: inherit

  h2
    color: get-color(bs-w)

  @include media-query.from(media.$md-min)
    text-align: center

    h2
      margin-block-end: rem-fn(60)

    .bs-w-button
      margin-inline: auto

.bs-w-button-chat
  align-items: center
  background-color: get-color(bs-g)
  block-size: rem-fn(61)
  border: 0
  border-radius: 50%
  bottom: rem-fn(100)
  box-shadow: $box-shadow-btn
  cursor: pointer
  display: flex
  inline-size: rem-fn(61)
  justify-content: center
  padding: 0
  position: absolute
  right: rem-fn(-100)
