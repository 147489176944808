@use "~booksprout-app/src/css/quasar.variables.sass" as *
@use "~booksprout-app/src/css/mixins/media.sass" as *
@use '~booksprout-app/src/css/functions/rem-fn' as *
@use '~booksprout-app/src/css/functions/line-height-fn' as *
@use '../css/functions/get-color' as *
@use '~booksprout-app/src/css/mixins/media' as media-query
@use '../css/variables/border-radius' as *
@use '../css/variables/box-shadow' as *
@use '../css/variables/media'

.bs-w-reviewer-preview
  .bs-w-member
    margin-block-end: rem-fn(40)

  @include media-query.from(media.$md-min)
    align-content: start
    display: grid
    row-gap: rem-fn(40)
    grid-template-columns: repeat(2, 1fr)

    .bs-w-member, .bs-w-reviewer-rating
      margin-block-end: 0

    .bs-w-review-group
      grid-column: span 2

.bs-w-reviewer-rating
  display: flex
  flex-direction: column
  margin-block-end: rem-fn(40s)

  strong
    color: get-color(bs-b)
    font-size: rem-fn(16)
    line-height: line-height(22, 16)
    margin-block-end: rem-fn(10)

.bs-w-review
  background-color: get-color(bs-w)
  border-radius: $border-radius-base
  box-shadow: $box-shadow-base
  margin-block-end: rem-fn(34) // 20px + half height of the type
  min-block-size: rem-fn(118)
  padding: rem-fn(30)
  position: relative

  &:last-child
    margin-block-end: rem-fn(74) // 60px + half height of the type

  .bs-w-tag
    bottom: rem-fn(-14)
    left: 50%
    position: absolute
    transform: translateX(-50%)

.bs-w-review-group
  @include media-query.from(media.$md-min)
    border-radius: $border-radius-base
    box-shadow: $box-shadow-base
    display: flex

    .bs-w-review
      box-shadow: none
      flex: 0 0 50%
      margin-block-end: 0

      &:first-child
        border-inline-end: rem-fn(1) solid #E0E0E0
        border-bottom-right-radius: 0
        border-top-right-radius: 0

      &:last-child
        border-bottom-left-radius: 0
        border-top-left-radius: 0
