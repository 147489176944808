@use "~booksprout-app/src/css/quasar.variables.sass" as *
@use "~booksprout-app/src/css/mixins/media.sass" as *
@use '~booksprout-app/src/css/functions/rem-fn' as *
@use '../css/functions/get-color' as *
@use '../css/variables/border-radius' as *
@use '../css/variables/box-shadow' as *

.bs-w-member-carousel
  height: auto
  block-size: rem-fn(326)

.bs-w-member-carousel-item
  border: rem-fn(2) solid transparent
  background-color: get-color(bs-w)
  border-radius: $border-radius-base
  box-shadow: $box-shadow-base
  margin-block-end: rem-fn(10)
  margin-inline: rem-fn(60)
  padding: rem-fn(20)
  position: relative

  &__active
    border-color: get-color(bs-go)
    margin-inline: 0
    padding: rem-fn(35)

    .bs-w-member
      img
        block-size: rem-fn(62)
        inline-size: rem-fn(62)

      figcaption
        strong
          font-size: rem-fn(18)
          font-weight: 700
          line-height: line-height(22, 18)

        span
          font-size: rem-fn(14)
          line-height: line-height(22, 14)

.bs-w-leaf-shade
  background-color: get-color(bs-go)
  block-size: rem-fn(81)
  border-top-right-radius: rem-fn(4)
  bottom: 0
  content: ''
  inline-size: rem-fn(60)
  position: absolute
  right: rem-fn(45)

.bs-w-leaf
  background-color: get-color(bs-g)
  block-size: rem-fn(102)
  border-radius: rem-fn(4) rem-fn(4) 0 0 // custom
  bottom: 0
  inline-size: rem-fn(70)
  padding-block-start: rem-fn(45)
  padding-inline: rem-fn(20)
  position: absolute
  right: rem-fn(90)

  img
    inline-size: rem-fn(30)
